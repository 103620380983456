// Full domain URL excluding leafs
import { useRouter } from "next/router"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "next-i18next"
import { AUTH_URL } from "src/env"

const useOriginUrl = () => {
  const router = useRouter()

  const { i18n } = useTranslation("common")

  const [originUrl, setOriginUrl] = useState<string>("")
  const [tnetAuthUrlWithCallback, setTnetAuthUrlWithCallback] = useState<string>("")

  useEffect(() => {
    if (typeof window !== "undefined") {
      setOriginUrl(window.location.origin)
    }
  }, [])

  useEffect(() => {
    setTnetAuthUrlWithCallback(`${AUTH_URL}/${i18n.language}/user/login/?Continue=${originUrl}${router.asPath}`)
  }, [originUrl, router.asPath])

  const initiateLogin = useCallback(() => {
    if (typeof window !== "undefined" && originUrl && router.asPath) {
      location.replace(`${AUTH_URL}/${i18n.language}/user/login/?Continue=${originUrl}${router.asPath}`)
    }
  }, [originUrl, router.asPath, tnetAuthUrlWithCallback])

  const setLanguage = (language: string) => {
    setTnetAuthUrlWithCallback(`${AUTH_URL}/${language}/user/login/?Continue=${originUrl}${router.asPath}`)
  }

  return { originUrl, tnetAuthUrlWithCallback, initiateLogin, setLanguage }
}

export default useOriginUrl
