import { STATIC_URL } from "src/env"
import useUser from "src/hooks/useUser"
import usePersonApi from "src/server/queryHooks/usePersonApi"

const CircularProgressChart = ({
  progress,
  size = 186,
  strokeWidth = 20,
  color = "#FFC133",
  imagePadding = 1
}: any) => {
  const { naturalPersonData } = usePersonApi()
  const { connectedUser } = useUser()

  if (!connectedUser) return null

  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius
  const offset = circumference - (progress / 100) * circumference

  const backgroundRadius = 154 / 2 + imagePadding
  const whiteCircleRadius = Math.min(radius, backgroundRadius)

  const imageSize = 154

  return (
    <svg width={size} height={size}>
      <circle
        stroke={progress === 100 ? "#37B37E" : color}
        fill="none"
        strokeWidth={strokeWidth}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        style={{ transition: "stroke-dashoffset 0.35s" }}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
      />
      <circle fill="white" cx={size / 2} cy={size / 2} r={whiteCircleRadius} />
      <image
        href={
          connectedUser?.user_id && naturalPersonData?.has_photo
            ? `${STATIC_URL}/myjobs/profile/${connectedUser?.user_id}.jpg?v=${naturalPersonData?.photo_version}`
            : "/images/default_image.svg"
        }
        x={(size - imageSize) / 2}
        y={(size - imageSize) / 2}
        width={imageSize}
        height={imageSize}
        clipPath="circle(50%)"
      />
    </svg>
  )
}

export default CircularProgressChart
