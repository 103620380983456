import { FieldsToCheck } from "./checkEmptyFields"

export type FieldsWithPercentagesType = {
  about_me: number
  work_experience: number
  education: number
  skills: number
  soft_skills: number
  certificates: number
  trainings: number
}

export const FieldsWithPercentages = {
  about_me: 5,
  work_experience: 35,
  education: 30,
  skills: 10,
  soft_skills: 5,
  certificates: 5,
  trainings: 10
}

const CountEmptyFieldsPercentage = (FieldsWithPercentages: any, emptyFields: Partial<FieldsToCheck>) => {
  let count = 0

  Object.keys(emptyFields).forEach(key => {
    count += FieldsWithPercentages[key]
  })

  return 100 - count
}

export default CountEmptyFieldsPercentage
