import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { ACCESS_TOKEN_NAME } from "src/env"
import Cookie from "src/utils/configs/Cookie"
import ConsentsService from "../services/ConsentsService"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"
import { IConsent, ISendUserConsent } from "src/types/consentType"

const useConsents = () => {
  const queryClient = useQueryClient()

  const token = Cookie.get(ACCESS_TOKEN_NAME)
  const userConsentsQuery = useQuery<IConsent>({
    queryKey: [QUERY_KEYS.GET_USER_CONSENTS],
    queryFn: () => getUserConsents(),
    retry: 1,
    enabled: !!token
  })

  const userExternalConsentsQuery = useQuery<IConsent>({
    queryKey: [QUERY_KEYS.GET_USER_EXTERNAL_CONSENTS],
    queryFn: () => getUserExternalConsents(),
    retry: 1,
    enabled: !!token
  })

  const userPostConsentMutation = useMutation<ISendUserConsent, unknown, ISendUserConsent>({
    mutationKey: [QUERY_KEYS.POST_USER_CONSENTS],
    mutationFn: postUserConsents,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.GET_USER_CONSENTS])
    }
  })

  const userExternalPostConsentMutation = useMutation<any, unknown, any>({
    mutationKey: [QUERY_KEYS.POST_USER_EXTERNAL_CONSENTS],
    mutationFn: postUserExternalConsents,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.GET_USER_EXTERNAL_CONSENTS])
    }
  })

  return {
    userConsentsQuery,
    userPostConsentMutation,
    userExternalConsentsQuery,
    userExternalPostConsentMutation
  }
}

export const postUserConsents = async (body?: any, req?: any) => {
  try {
    const response: any = await ConsentsService.postUserConsents(body, req)
    return response
  } catch (e: any) {
    console.error("Error posting User Consents:", e)
    throw e
  }
}

export const getUserConsents = async (req?: any) => {
  try {
    const response: any = await ConsentsService.getUserConsents(req)
    return response.data
  } catch (e: any) {
    console.error("Error fetching User Consents:", e)
    throw e // Rethrow the error to be handled by useQuery
  }
}

export const getUserExternalConsents = async (req?: any) => {
  try {
    const response: any = await ConsentsService.getUserExternalConsents(req)
    return response.data
  } catch (e: any) {
    console.error("Error fetching User External Consents:", e)
    throw e
  }
}

export const postUserExternalConsents = async (req?: any) => {
  try {
    const response: any = await ConsentsService.postUserExternalConsents(req)
    return response.data
  } catch (e: any) {
    console.error("Error fetching User External Consents:", e)
    throw e
  }
}

export default useConsents
