// ** Tailwind Styled
import tw from "tailwind-styled-components"

// SFM = SearchboxFilterModal
export const DialogPosition = tw.div` fixed inset-0 p-4 min-h-full flex items-center justify-center`
export const DialogContaier = tw.div`flex flex-col bg-white w-full h-full max-h-[747px] max-w-modal overflow-hidden rounded-1.5xl shadow-card-box border border-neutral-20`
export const DialogHeader = tw.div`px-8 pt-8 pb-6 flex items-center justify-between border-b border-neutral-20`
export const DialogBody = tw.div`py-6 px-8 overflow-y-auto`
export const DialogFooter = tw.div` px-8 py-6 flex items-center justify-between border-t border-neutral-20`

export const SalaryRangeBox = tw.div`flex flex-col gap-6 pb-7`
