import { useState } from "react"
import ModalWrapper from "../Modal/ModalWrapper"
import Typography from "../typography"
import { Dialog } from "@headlessui/react"
import Button from "../common/Button/Button"
import ModifyTnetProducts from "./ModifyTnetProducts"
import PartnerCompaniesModal from "./PartnerCompaniesModal"
import { IConsent, IProduct, IOffer, ISendUserConsent } from "src/types/consentType"
import { ReplaceTextWithLinks } from "src/utils/helpers/consents/ReplaceTextWithLinks"
import useConsents from "src/server/queryHooks/useConsents"

import Cookies from "js-cookie"

interface Props {
  isOpen: boolean
  onClose: () => void
  className?: string
  height?: string
  width?: string
  consentData: IConsent
}

const ConsentModal = ({ isOpen, onClose, className, consentData }: Props) => {
  const { userPostConsentMutation } = useConsents()

  const initialOffers = consentData
    ? consentData.offers.map(item => ({
        scope: item.scope,
        priority: item.priority,
        products: item.products.map(product => ({
          uuid: product.uuid,
          accept: false
        }))
      }))
    : []

  const [consent, setConsent] = useState<{ offers: IOffer[] }>({ offers: initialOffers || [] })
  const [step, setStep] = useState("Basic")

  const handleModify = () => setStep("ModifyTnetProducts")

  const handleRejectAll = () => {
    if (Object.keys(consent).length > 0 && !userPostConsentMutation.isLoading) {
      const rejectAll: ISendUserConsent = {
        offers: consent.offers.map((item: IOffer) => ({
          ...item,
          products: item.products.map((product: IProduct) => ({
            uuid: product.uuid,
            accept: false
          }))
        }))
      }

      userPostConsentMutation.mutate(rejectAll)
      onClose()
    }

    const consentResponseTime = new Date().toUTCString()

    sessionStorage.setItem("consentTime", consentResponseTime)
  }

  const handleSubmit = () => {
    if (Object.keys(consent).length > 0 && !userPostConsentMutation.isLoading) {
      const updatedConsent: ISendUserConsent = {
        offers: consent.offers.map((item: IOffer) => ({
          ...item,
          products: item.products.map((product: IProduct) => ({
            uuid: product.uuid,
            accept: true
          }))
        }))
      }
      setConsent(updatedConsent)
      userPostConsentMutation.mutate(updatedConsent)
      onClose()
    }

    const consentResponseTime = new Date().toUTCString()

    sessionStorage.setItem("consentTime", consentResponseTime)
  }

  return (
    <>
      {step === "Basic" && (
        <ModalWrapper
          isOpen={isOpen}
          onClose={onClose}
          width="664px"
          height="md:280px"
          className={`justify-start p-4 text-start md:px-8 md:py-6 ${className}`}
        >
          <Dialog.Title className="sticky top-0 z-10 flex bg-white pb-4">
            <Typography type="h5" weight="bold">
              {consentData.title}
            </Typography>
          </Dialog.Title>
          <div className="mb-4 flex flex-col gap-4 md:mb-5">
            <Typography type="text">{ReplaceTextWithLinks(consentData.agreements, consentData.document[0])}</Typography>
            <Typography type="text">{consentData.document[1]}</Typography>
            <Typography type="text">
              {consentData.document[2]}
              <u>
                <a href={consentData.agreements[2].href} className="cursor-pointer pl-1" target="_blank">
                  {consentData.agreements[2].title}
                </a>
              </u>
            </Typography>
          </div>
          <div className="flex flex-col flex-col-reverse justify-end sm:flex-row sm:gap-5">
            <div className="flex gap-3">
              <Button
                text="არ ვეთანხმები"
                intent="ghost"
                weight="medium"
                className="w-full px-0 text-secondary-50 hover:bg-transparent sm:w-auto"
                onClick={handleRejectAll}
              />
              <Button
                text="მოდიფიკაცია"
                intent="ghost"
                weight="medium"
                onClick={handleModify}
                className="w-full px-0 text-secondary-50 hover:bg-transparent sm:w-auto"
              />
            </div>
            <div>
              <Button
                text="ვეთანხმები"
                intent="primary"
                className="w-full cursor-pointer px-10 py-1"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </ModalWrapper>
      )}
      {step === "ModifyTnetProducts" && (
        <ModifyTnetProducts setStep={setStep} consentData={consentData} consent={consent} setConsent={setConsent} />
      )}
      {step === "PartnerCompaniesModal" && (
        <PartnerCompaniesModal setStep={setStep} consentData={consentData} consent={consent} setConsent={setConsent} />
      )}
    </>
  )
}

export default ConsentModal
