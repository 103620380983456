import { useEffect, useState } from "react"
import { useRouter } from "next/navigation"
import Image from "next/image"
import usePersonApi from "src/server/queryHooks/usePersonApi"
import useUser from "src/hooks/useUser"

import CircularProgressChart from "./CircularProgressChart/CircularProgressChart"
import Typography from "../typography"
import { checkEmptyFields } from "src/utils/helpers/profile/checkEmptyFields"
import CountEmptyFieldsPercentage from "src/utils/helpers/profile/countEmptyFieldsPercentage"
import { FieldsWithPercentages, FieldsWithPercentagesType } from "src/utils/helpers/profile/countEmptyFieldsPercentage"
import ShowMoreModal from "./Modal/ShowMoreModal"
import { useTranslation } from "next-i18next"
import Cookie from "src/utils/configs/Cookie"
import { getLanguageFromUrlOrCookie } from "src/utils/helpers/Language"

const CompleteProfileCard = () => {
  const { t } = useTranslation("common")
  const {
    naturalPersonQuery: { data, status, isError }
  } = usePersonApi()
  const { connectedUser } = useUser()
  const { naturalPersonData } = usePersonApi()

  const [openModal, setOpenModal] = useState(false)
  const [activeUserProgress, setActiveUserProgress] = useState(0)

  const cardOpenStatus = sessionStorage.getItem("ProgressCardOpen") === "false" ? false : true
  const [cardOpen, setCardOpen] = useState(cardOpenStatus)

  const emptyFields = checkEmptyFields(data)

  const router = useRouter()

  useEffect(() => {
    if (data) {
      const progress = CountEmptyFieldsPercentage(FieldsWithPercentages, emptyFields)
      setActiveUserProgress(progress)
    }
  }, [data])

  useEffect(() => {
    const openCard = sessionStorage.getItem("ProgressCardClick") ?? null
    const closeCard = sessionStorage.getItem("ProgressCardOpen") ?? null
    const progress = CountEmptyFieldsPercentage(FieldsWithPercentages, emptyFields)

    if (progress === 100 && !openCard) {
      setCardOpen(false)
    }

    if (progress !== 100 && !openCard && closeCard === null) {
      setCardOpen(true)
    }

    if (progress === 100 && openCard) {
      setCardOpen(true)

      const timer = setTimeout(() => {
        setCardOpen(false)
        sessionStorage.removeItem("ProgressCardClick")
      }, 180000)

      return () => clearTimeout(timer)
    }
  }, [emptyFields])

  const handleCloseCard = () => {
    sessionStorage.removeItem("ProgressCardClick")
    setCardOpen(false)
    sessionStorage.setItem("ProgressCardOpen", "false")
  }

  const handleClick = (value: string) => {
    sessionStorage.setItem("ProgressCardClick", "clicked")
    router.push(`/profile?param=${value}`)
  }

  return (
    <>
      {data && cardOpen && (
        <div className="hidden min-h-[365px] max-w-[1030px] rounded-2xl border border-neutral-20 xl:flex m-auto mb-14">
          <div
            className="flex basis-1/3 flex-col items-center justify-center rounded-l-2xl bg-neutral-20"
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <div className="relative w-[186px] h-[186px] flex justify-center items-center">
              <CircularProgressChart progress={activeUserProgress} />
            </div>
            <Typography type="text" className="mt-4">
              {activeUserProgress}% {t("FILLED")}
            </Typography>
          </div>
          <div>
            <div className="flex items-end justify-end mr-7 mt-7 cursor-pointer" onClick={handleCloseCard}>
              <Image src="/images/close.svg" width={20} height={20} alt="close" />
            </div>
            <div className="flex flex-col mb-9 mx-14">
              <Typography
                type="h3"
                className="text-primary-100 pb-3 cursor-pointer"
                onClick={() => router.replace("/profile")}
              >
                {activeUserProgress !== 100 ? t("FINISH_PROFILE") : t("FINISHED_PROFILE")}
              </Typography>
              <Typography type="text" className="text-secondary-100 leading-7	w-[620px] pb-6">
                {t("COMPLETELY_FILLED_PROFILE")}
              </Typography>
              <div className="flex flex-col gap-6 cursor-pointer">
                {Object.entries(emptyFields)
                  .slice(0, 2)
                  .map(([key, value]) => (
                    <div className="border-b" onClick={() => handleClick(key)} key={key}>
                      <div className="flex justify-between items-center gap-3" key={key + " container"}>
                        <div className="flex items-center gap-3 pb-3" key={key + " content"}>
                          <Image src="/images/circle.svg" width={20} height={20} alt="circle" />
                          <Typography type="h5" weight="bold" className="cursor-pointer">
                            {t("UADD")} {value}
                          </Typography>
                        </div>
                        <button className="pt-5" onClick={() => handleClick(key)} key={key + " button"}>
                          <Image src="/images/arrow_right.svg" width={8} height={12} alt="arrow" />
                        </button>
                      </div>
                      {getLanguageFromUrlOrCookie() == 'en' ? (
                        <Typography type="text" className="text-secondary-100 pb-4 cursor-pointer" key={key + " text"}>
                          {FieldsWithPercentages[key as keyof FieldsWithPercentagesType]}% of a full profile
                        </Typography>
                      ) : (
                        <Typography type="text" className="text-secondary-100 pb-4 cursor-pointer" key={key + " text"}>
                          სრულყოფილი პროფილის {FieldsWithPercentages[key as keyof FieldsWithPercentagesType]}%
                        </Typography>
                      )}
                    </div>
                  ))}
              </div>
              {Object.keys(emptyFields).length > 2 && (
                <Typography
                  type="text"
                  className="underline text-primary-100 mt-4 cursor-pointer"
                  onClick={() => setOpenModal(true)}
                >
                  {t("MORE_SHOW")}
                </Typography>
              )}
            </div>
          </div>
          {openModal && <ShowMoreModal modalOpen={openModal} setModalOpen={setOpenModal} emptyFields={emptyFields} />}
        </div>
      )}
    </>
  )
}

export default CompleteProfileCard
