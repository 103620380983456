import React, { forwardRef } from "react"
import Typography from "../../../typography"
import Image from "next/image"
import { cx } from "class-variance-authority"
import { twMerge } from "tailwind-merge"

interface Props {
  name: string
  label: string
  selected: boolean
  className?: string
  onChange?: any
}

const Checkbox = forwardRef<HTMLInputElement, Props>(({ name, label, selected, onChange, className, ...rest }, ref) => {
  return (
    <div
      className={twMerge(
        cx(
          "relative flex w-full cursor-pointer select-none items-center rounded-lg px-4 py-3  transition-all",
          className
        )
      )}
    >
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={selected}
        onChange={onChange}
        ref={ref}
        className={cx(
          `peer relative h-5 w-5 flex-shrink-0 cursor-pointer appearance-none rounded border border-neutral-50 bg-white `,
          "checked:border-primary-100 checked:bg-primary-100"
        )}
        {...rest}
      />
      {selected && (
        <Image
          src="/images/bi_check.svg"
          width={13}
          height={13}
          className="pointer-events-none absolute ml-1 hidden peer-checked:block"
          alt="check"
        />
      )}

      <label className="cursor-pointer pl-2.5" htmlFor={name}>
        <Typography type="text">{label}</Typography>
      </label>
    </div>
  )
})
export default Checkbox
