import HttpService from "./HttpService"

class ConsentsService extends HttpService {
  getUserConsents(req?: any) {
    return this.get("/tnet/popup", {}, null, req)
  }

  postUserConsents(body?: any, req?: any) {
    return this.post("/tnet/popup", body, null, req)
  }

  getUserExternalConsents(req?: any) {
    return this.get("/tnet/popup/external", {}, null, req)
  }

  postUserExternalConsents(body?: any, req?: any) {
    return this.post("/tnet/popup/external", body, null, req)
  }
}

export default new ConsentsService()
