import Cookies from "js-cookie"

export function checkConsentTime() {
  const savedTime = sessionStorage.getItem("consentTime")

  if (savedTime) {
    const savedDate = new Date(savedTime)

    if (!isNaN(savedDate.getTime())) {
      const currentDate = new Date()

      const timeDifference = currentDate.getTime() - savedDate.getTime()

      const thirtyMinutes = 30 * 60 * 1000

      if (timeDifference > thirtyMinutes) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  } else {
    return false
  }
}
