export type FieldsToCheck = {
  about_me: string
  work_experience: string
  education: string
  skills: string
  soft_skills: string
  certificates: string
  trainings: string
}

export const checkEmptyFields = (data: any) => {
  const fieldsToCheck: FieldsToCheck = {
    about_me: "ჩემს შესახებ",
    work_experience: "მუშაობის გამოცდილება",
    education: "განათლება",
    skills: "პროფესიული უნარები",
    soft_skills: "პიროვნული უნარები",
    certificates: "სერთიფიკატები",
    trainings: "ტრენინგები"
  }

  const emptyFields: Partial<FieldsToCheck> = {}

  ;(Object.keys(fieldsToCheck) as Array<keyof FieldsToCheck>).forEach(key => {
    if (data?.[key] === null || data?.[key].length < 1) {
      emptyFields[key] = fieldsToCheck[key]
    }
  })

  return emptyFields
}
