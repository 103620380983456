import { useEffect, useState } from "react"
import { ITokenTnetUser } from "src/types/commonTypes"
import Cookie from "src/utils/configs/Cookie"
import { getUserData } from "../utils/configs/jwt"
import { ACCESS_TOKEN_NAME } from "src/env"

const useUser = () => {
  const [connectedUser, setConnectedUser] = useState<ITokenTnetUser | null>(null)

  useEffect(() => {
    const AccessToken = Cookie.get(ACCESS_TOKEN_NAME)
    if (!AccessToken) {
      setConnectedUser(null)
    } else {
      const user = getUserData(AccessToken)
      // ConnectedUser state
      setConnectedUser(user)
    }
  }, []) // empty dependency array ensures this runs only once

  return {
    connectedUser,
    isConnected: !!connectedUser // this can be a more reliable check
  }
}

export default useUser
